@import '../node_modules/animate.css/animate.min.css';
@import '../node_modules/aos/dist/aos.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import '../node_modules/boxicons/css/boxicons.min.css';
@import "../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
@import '../node_modules/remixicon/fonts/remixicon.css';
@import '../node_modules/font-awesome/css/font-awesome.min.css';
@import '../node_modules/toastr/build/toastr.css';
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';
@import "~react-image-gallery/styles/css/image-gallery.css";

body::-webkit-scrollbar,
.modal-body::-webkit-scrollbar {
  display: none;
}

.form-select>option {
  padding: 10px;
  margin: 10px;
}

.modal-body {
  background: transparent !important;
  background-color: transparent !important;
  border: none !important;
  backface-visibility: hidden !important;
}

.cursor-pointer {
  cursor: pointer;
}

.view-title-link {
  position: relative;
  float: right;
  right: 15px;
  margin-top: 20px;
}

.about-image-box {
  background-image: url(./assets/img/portfolio/IMAGE_17.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

}

.main-gallery-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.fluid-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 600px;
}


/*------/ Icon Box /------*/
.icon-box .icon-box-icon {
  display: table-cell;
  vertical-align: top;
  font-size: 36px;
  color: #000000;
  width: 50px;
  padding-top: 8px;
}

.icon-box .icon-box-content {
  padding-top: 18px;
}

/*------/ Space Padding /------*/
.section-t8 {
  padding-top: 8rem;
}

@media (max-width: 767px) {
  .section-t8 {
    padding-top: 4rem;
  }
}

.section-t4 {
  padding-top: 4rem;
}

@media (max-width: 767px) {
  .section-t4 {
    padding-top: 2rem;
  }
}

.section-t3 {
  padding-top: 3rem;
}

@media (max-width: 767px) {
  .section-md-t3 {
    padding-top: 3rem;
  }
}

.section-b2 {
  padding-bottom: 2rem;
}

.section-tb85 {
  padding: 8rem 0 5rem 0;
}

@media (max-width: 767px) {
  .section-tb85 {
    padding: 4rem 0 2.5rem 0;
  }
}

/*------/ Title /------*/
.title-wrap {
  padding-bottom: 4rem;
}

.title-a {
  text-decoration: none;
  font-size: 2.6rem;
  font-weight: 600;
}

.title-link {
  font-size: 1.2rem;
  font-weight: 300;
  padding-top: 1.2rem;
}

.title-link a {
  text-decoration: none;
  color: #313131;
}

.title-link span {
  font-size: 20px;
  padding-left: 4px;
  vertical-align: middle;
}

.title-box-d {
  padding-bottom: 1.8rem;
  margin-bottom: 1rem;
  position: relative;
}

.title-box-d .title-d {
  font-weight: 600;
  font-size: 2rem;
}

.title-box-d .title-d:after {
  content: "";
  position: absolute;
  width: 70px;
  height: 4px;
  background-color: #2eca6a;
  bottom: 20px;
  left: 0;
}

/*------/ Display Table & Table Cell /------*/
.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

/*------/ Ul Resect /------*/
.ul-resect ul,
.box-comments ul,
.list-a ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

/*------/ Overlay /------*/
.overlay-a {
  text-decoration: none;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.65) 90%);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
}

.carousel-pagination {
  margin-top: 10px;
  text-align: center;
}

.carousel-pagination .swiper-pagination-bullet {
  width: 18px;
  height: 10px;
  background-color: #555;
  margin: 0 4px;
  border-radius: 0;
  opacity: 1;
  transition: 0.3s;
}

.carousel-pagination .swiper-pagination-bullet-active {
  background-color: #2eca6a;
  width: 36px;
}

.nav-arrow-a {
  text-decoration: none;
}

.nav-arrow-a .owl-arrow .owl-nav {
  font-size: 1.8rem;
  margin-top: -110px;
}

.nav-arrow-a .owl-arrow .owl-nav .owl-next {
  margin-left: 15px;
}

.nav-arrow-b .owl-arrow .owl-nav {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.2rem;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-prev,
.nav-arrow-b .owl-arrow .owl-nav .owl-next {
  padding: 0.7rem 1.5rem !important;
  display: inline-block;
  transition: all 0.6s ease-in-out;
  color: #ffffff;
  background-color: #2eca6a;
  opacity: 0.9;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-prev.disabled,
.nav-arrow-b .owl-arrow .owl-nav .owl-next.disabled {
  transition: all 0.6s ease-in-out;
  color: #ffffff;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-prev:hover,
.nav-arrow-b .owl-arrow .owl-nav .owl-next:hover {
  background-color: #26a356;
}

.nav-arrow-b .owl-arrow .owl-nav .owl-next {
  margin-left: 0px;
}

/*------/ Socials /------*/
.socials-a {
  text-decoration: none;
}

.socials-a .list-inline-item:not(:last-child) {
  margin-right: 25px;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #2eca6a;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #4bd680;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/* Prelaoder */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f2f2f2;
  border-top: 6px solid #2eca6a;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.bg-blur{
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

/*======================================
//--//-->   BOX COLAPSE
======================================*/
.box-collapse {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  color: white;
  z-index: 1040;
  background-color: black;
  transform: translateX(100%);
  transition: all 0.6s ease;
  filter: none !important;
  -webkit-filter: none !important;
}

@media (min-width: 768px) {
  .box-collapse {
    width: 50%;
  }
}

.box-collapse .title-box-d {
  top: 30px;
  left: 60px;
  opacity: 0;
  transition: all 1s ease;
  transition-delay: 0.3s;
}

@media (max-width: 575px) {
  .box-collapse .title-box-d {
    left: 35px;
  }
}

@media (max-width: 575px) {
  .box-collapse .title-box-d .title-d {
    font-size: 1.3rem;
  }
}

.box-collapse-wrap {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 6.5rem;
  bottom: 5rem;
  padding-left: 10%;
  padding-right: 10%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  transform: translateY(3rem);
  transition: transform 0.5s 0.5s ease, opacity 0.5s 0.5s ease;
}

.box-collapse-open .click-closed {
  visibility: visible;
}

.box-collapse-open .box-collapse {
  transform: translateX(0);
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.07);
  opacity: 1;
}

.box-collapse-open .box-collapse .title-box-d {
  transform: translate(0);
  opacity: 1;
}

.box-collapse-open .box-collapse-wrap {
  transform: translate(0);
  opacity: 1;
}

.box-collapse-closed .box-collapse {
  opacity: 0.7;
  transition-delay: 0s;
}

.box-collapse-closed .box-collapse .title-box-d {
  opacity: 0;
  transition-delay: 0s;
}

.box-collapse-closed .box-collapse .form-a {
  opacity: 0;
  transition-delay: 0s;
}

.click-closed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 1035;
  background-color: #000000;
  opacity: 0.4;
}

.close-box-collapse {
  position: absolute;
  z-index: 1050;
  top: 2rem;
  font-size: 3.5rem;
  line-height: 1;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.right-boxed {
  right: 10px;
  top: 10px;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.intro {
  height: 100vh;
  position: relative;
  color: #ffffff;
}

.intro .swiper-pagination {
  bottom: 50px;
}

.intro .swiper-pagination .swiper-pagination-bullet {
  width: 18px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 0;
  opacity: 1;
  transition: 0.3s;
}

.intro .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2eca6a;
  width: 36px;
}

.intro .intro-item {
  padding-top: 2rem;
}

.intro .carousel-item-a {
  position: relative;
  height: 100vh;
}

.intro .carousel-item-a.bg-image {
  background-attachment: scroll;
}

.intro .intro-content {
  position: absolute;
}

.intro .intro-body {
  padding-left: 1rem;
}

@media (min-width: 768px) {
  .intro .intro-body {
    padding-left: 2rem;
  }
}

.intro .intro-body .price-a {
  color: #ffffff;
  padding: 1rem 1.5rem;
  border: 2px solid #2eca6a;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.intro .spacial {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  color: #ffffff;
}

.intro .intro-title-top {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #ffffff;
  letter-spacing: 0.05em;
}

@media (min-width: 768px) {
  .intro .intro-title-top {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .intro .intro-title-top {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
}

.intro .intro-title {
  color: #ffffff;
  font-weight: 700;
  font-size: 2.8rem;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .intro .intro-title {
    font-size: 3.5rem;
  }
}

@media (min-width: 768px) {
  .intro .intro-title {
    font-size: 3.5rem;
  }
}

@media (min-width: 992px) {
  .intro .intro-title {
    font-size: 4rem;
  }
}

.intro .intro-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

@media (min-width: 768px) {
  .intro .intro-subtitle {
    font-size: 2.5rem;
  }
}

.intro .intro-subtitle.intro-price {
  padding-top: 1rem;
  font-size: 1.2rem;
}

@media (min-width: 576px) {
  .intro .intro-subtitle.intro-price {
    font-size: 1.3rem;
  }
}

@media (min-width: 768px) {
  .intro .intro-subtitle.intro-price {
    padding-top: 2rem;
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .intro .intro-subtitle.intro-price {
    padding-top: 3rem;
  }
}

/*------/ Intro Single /------*/
.intro-single {
  padding: 12rem 0 3rem;
}

@media (max-width: 767px) {
  .intro-single {
    padding-top: 9rem;
  }
}

.intro-single .title-single-box {
  padding: 1rem 0 1rem 2rem;
}

.intro-single .title-single-box {
  border-left: 3px solid #2eca6a;
}

.intro-single .title-single-box .title-single {
  font-weight: 600;
  font-size: 2.2rem;
}

@media (min-width: 768px) {
  .intro-single .title-single-box .title-single {
    font-size: 2.1rem;
  }
}

@media (min-width: 992px) {
  .intro-single .title-single-box .title-single {
    font-size: 2.5rem;
  }
}

.intro-single .breadcrumb-box {
  padding: 1rem 0 0 0.5rem;
}

.intro-single .breadcrumb {
  background-color: transparent;
  padding-right: 0;
  padding-left: 0;
}

/*--------------------------------------------------------------
# Card General
--------------------------------------------------------------*/
@media (max-width: 767px) {

  .card-box-a,
  .card-box-b,
  .card-box-c,
  .card-box-d {
    margin-bottom: 2.5rem;
  }
}

.card-box-a span,
.card-box-b span,
.card-box-c span,
.card-box-d span {
  line-height: 0;
}

@media (min-width: 768px) {

  .grid .card-box-a,
  .grid .card-box-b,
  .grid .card-box-c,
  .grid .card-box-d {
    margin-bottom: 2.5rem;
  }
}

.card-box-a,
.card-box-b,
.card-box-d {
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-box-a .img-a,
.card-box-a .img-b,
.card-box-b .img-a,
.card-box-b .img-b {
  transition: 0.8s all ease-in-out;
}

@media (min-width: 768px) {

  .card-box-a:hover .img-a,
  .card-box-a:hover .img-b,
  .card-box-b:hover .img-a,
  .card-box-b:hover .img-b {
    transform: scale(1.2);
  }
}

@media (min-width: 768px) {

  .card-box-a .price-a,
  .card-box-b .price-a {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {

  .card-box-a .price-a,
  .card-box-b .price-a {
    font-size: 1rem;
  }
}



.card-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.card-shadow {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-shadow:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.65) 90%);
}

/*======================================
//--//-->   PROPERTY - CARD-A
======================================*/
@media (min-width: 768px) {
  .card-box-a:hover .card-overlay-a-content {
    bottom: 60px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .card-box-a:hover .card-overlay-a-content {
    bottom: 60px;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .card-box-a:hover .card-overlay-a-content {
    bottom: 64px;
  }
}

@media (min-width: 768px) {
  .card-box-a:hover .card-body-a {
    padding-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .card-box-a .card-overlay-a-content {
    bottom: 65px;
  }

  .card-box-a .card-body-a {
    padding-bottom: 1rem;
  }
}

.card-overlay-a-content {
  transition: all 0.5s ease-in;
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 2;
}

@media (min-width: 768px) {
  .card-overlay-a-content {
    bottom: -20px;
  }
}

@media (min-width: 992px) {
  .card-overlay-a-content {
    bottom: 0px;
  }
}

.card-header-a {
  padding: 0 1rem;
}

.card-header-a .card-title-a {
  color: #ffffff;
  margin-bottom: 0;
  padding-bottom: 0.7rem;
}

@media (min-width: 768px) {
  .card-header-a .card-title-a {
    font-size: 1.3rem;
  }
}

@media (min-width: 992px) {
  .card-header-a .card-title-a {
    font-size: 2rem;
  }
}

.card-header-a .card-title-a a {
  color: #ffffff;
  text-decoration: none;
}

.card-body-a {
  z-index: 2;
  transition: all 0.5s ease-in;
  padding: 0rem 1rem 2rem 1rem;
}

.card-body-a .price-box {
  padding-bottom: 0.5rem;
}

.price-a {
  color: #ffffff;
  padding: 0.6rem 0.8rem;
  border: 2px solid #2eca6a;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.card-footer-a {
  width: 100%;
  position: absolute;
  z-index: 2;
  background-color: #2eca6a;
}

.card-info {
  list-style: none;
  margin-bottom: 0;
  padding: 0.5rem 0;
}

.card-info .card-info-title {
  font-size: 1rem;
  color: #313131;
}

@media (min-width: 768px) {
  .card-info .card-info-title {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .card-info .card-info-title {
    font-size: 1rem;
  }
}

.card-info li span {
  color: #ffffff;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .card-info li span {
    font-size: 0.8rem;
  }
}

@media (min-width: 992px) {
  .card-info li span {
    font-size: 1rem;
  }
}

/*------/ Property Grid /-----*/
.property-grid .grid-option {
  text-align: right;
  margin-bottom: 2rem;
}

.property-grid .grid-option .custom-select {
  border-radius: 0;
  width: 200px;
  border-color: #313131;
}

.property-grid .grid-option .custom-select:hover,
.property-grid .grid-option .custom-select:active,
.property-grid .grid-option .custom-select:focus {
  border-color: #313131;
}

.property-grid .grid-option .custom-select option {
  border-color: #313131;
}

/*------/ Property Single /-----*/
.gallery-property {
  margin-bottom: 3rem;
}

.property-price {
  margin: 0 auto;
}

.property-price .card-header-c {
  padding: 0;
}

.summary-list {
  padding-right: 1rem;
  color: #000000;
}

.summary-list .list {
  padding: 0;
  line-height: 2;
}

.summary-list .list span {
  color: #555555;
}

@media (max-width: 767px) {
  .property-agent {
    margin-top: 2.5rem;
  }
}

.property-agent .title-agent {
  font-weight: 600;
}

.property-agent ul {
  line-height: 2;
  color: #000000;
}

.property-agent .socials-a {
  text-align: center;
}

@media (max-width: 991px) {
  .property-contact {
    margin-top: 2.5rem;
  }
}

/*======================================
//--//-->   NEWS - CARD-B
======================================*/
.card-header-b {
  padding: 1rem;
  color: #ffffff;
  position: absolute;
  bottom: 20px;
  z-index: 2;
}

@media (min-width: 768px) {
  .card-header-b {
    bottom: 0px;
  }
}

@media (min-width: 992px) {
  .card-header-b {
    bottom: 20px;
  }
}

.card-header-b .category-b {
  font-size: 0.9rem;
  background-color: #2eca6a;
  padding: 0.3rem 0.7rem;
  color: #313131;
  letter-spacing: 0.03em;
  border-radius: 50px;
  text-decoration: none;
}

.card-header-b .title-2 {
  margin-bottom: 0;
  padding: 0.6rem 0;
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  .card-header-b .title-2 {
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .card-header-b .title-2 {
    font-size: 1.6rem;
  }
}

.card-header-b .title-2 a {
  color: #ffffff;
  text-decoration: none;
}

.card-header-b .date-b {
  color: #d8d8d8;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .card-header-b .date-b {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .card-header-b .date-b {
    font-size: 1rem;
  }
}


/*======================================
//--//-->   SERVICES - CARD-C
======================================*/
.card-box-c {
  position: relative;
}

@media (max-width: 767px) {
  .card-box-c {
    margin-bottom: 2rem;
  }
}

.card-header-c {
  padding: 0 1rem 1rem 1rem;
}

.card-body-c {
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.content-c {
  right: 50%;
}

@media (max-width: 767px) {
  .card-body-c {
    padding: 0;
  }
}

.card-footer-c {
  padding-left: 0.5rem;
}

.card-box-ico {
  padding: 1rem 3rem 1rem 2.5rem;
  border: 0px solid #2eca6a;
}

.card-box-ico span {
  font-size: 4rem;
  color: #000000;
}

.title-c {
  font-size: 2.5rem;
  font-weight: 350;
  margin-left: -45px;
}

@media (min-width: 768px) {
  .title-c {
    font-size: 1.8rem;
  }
}

@media (min-width: 992px) {
  .title-c {
    font-size: 2.5rem;
  }
}

/*======================================
//--//-->   AGENTS - CARD-D
======================================*/
.card-box-d .card-overlay-hover {
  transition: all 0.2s ease-in-out;
  padding: 15px 40px 15px 35px;
}

@media (min-width: 768px) {
  .card-box-d .card-overlay-hover {
    padding: 5px 15px 5px 10px;
  }
}

@media (min-width: 992px) {
  .card-box-d .card-overlay-hover {
    padding: 5px 40px 5px 35px;
  }
}

@media (min-width: 1200px) {
  .card-box-d .card-overlay-hover {
    padding: 15px 40px 5px 35px;
  }
}

.card-box-d .title-d {
  transition: 0.3s ease-in-out;
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem 0;
  transform: translateY(-20px);
  opacity: 0;
}

@media (min-width: 768px) {
  .card-box-d .title-d {
    font-size: 1.1rem;
    margin: 0.5rem 0;
  }
}

@media (min-width: 992px) {
  .card-box-d .title-d {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
}

@media (min-width: 1200px) {
  .card-box-d .title-d {
    font-size: 2rem;
    margin: 1rem 0;
  }
}

.card-box-d .content-d {
  opacity: 0;
  transition: 0.5s ease-in-out;
  transform: translateY(-40px);
}

@media (min-width: 768px) {
  .card-box-d .content-d {
    font-size: 0.8rem;
    margin: 0.5rem 0;
  }
}

@media (min-width: 768px) {
  .card-box-d .card-body-d p {
    margin-bottom: 8px;
    font-size: 0.8rem;
  }
}

@media (min-width: 992px) {
  .card-box-d .card-body-d p {
    margin-bottom: 12px;
    font-size: 1rem;
  }
}

.card-box-d .info-agents {
  opacity: 0;
  transition: 0.5s ease-in-out;
}

.card-box-d .card-footer-d {
  transition: 0.5s ease-in-out;
  transform: translateY(40px);
  opacity: 0;
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
}

.card-box-d .list-inline-item:not(:last-child) {
  margin-right: 25px;
}

.card-box-d:hover .card-overlay-hover {
  background-color: #2eca6a;
  opacity: 0.9;
}

.card-box-d:hover .title-d,
.card-box-d:hover .content-d,
.card-box-d:hover .info-agents,
.card-box-d:hover .card-footer-d {
  opacity: 1;
  transform: translateY(0);
}



/* spinner-border text-primary justify-self-center align-self-center */

.loader {
  position: absolute;
  left: 50%;
}



.reviews-area {
  background: url(./assets/img/post-single-2.jpg);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  width: 100%;
  height: auto;
  position: relative;
}

.work-right-text {
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  color: #ffffff;
}


.ready-btn {
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  margin-top: 30px;
  padding: 12px 40px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
}

.ready-btn:hover {
  color: black;
  background-color: #ffffff;
}

.datalist-expanded::-webkit-scrollbar {
  display: none;
}

.datalist-expanded {
  height: 20px;
  overflow: scroll;
  position: absolute;
}

.react-datalist-input__textbox {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.react-datalist-input__textbox:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25)
}
#floating-button ::after,
#floating-button :active,
#floating-button :hover {
  background-color: white;
}

#floating-button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #2AE149;
  position: fixed;
  bottom: 25px;
  left: 10px;
  background-image: url(./assets/Icons/phone.gif);
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.plus {
  color: white;
  position: absolute;
  top: 0;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 55px;
  font-size: 38px;
  font-family: 'Roboto';
  font-weight: 300;
  animation: plus-out 0.3s;
  transition: all 0.3s;
}

#container-floating {
  position: fixed;
  width: 1px;
  height: 70px;
  bottom: 30px;
  left: 10px;
  z-index: 99999999;
}

#container-floating:hover {
  height: 400px;
  width: 90px;
  padding: 30px;
}

.switch{
  position: relative;
  display: inline-block;
  width: 80px;
  height: 37px;
  border-radius: 37px;
  background-color: red;
  cursor: pointer;
  transition: all .3s;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(0,0,0, .3);
}
.switch input{
  display: none;
}
.switch input:checked + div{
  left: calc(80px - 32px);
  box-shadow: 0px 0px 0px white;
}
.switch div{
  position: absolute;
  width: 27px;
  height: 27px;
  border-radius: 27px;
  background-color: white;
  color: white;
  top: 5px;
  left: 5px;
  box-shadow: 0px 0px 1px rgb(150,150,150);
  transition: all .3s;
}
.switch div:before, .switch div:after{
  position: absolute;
  content: 'YES';
  margin-left: 10px;
  width: calc(80px - 40px);
  height: 37px;
  line-height: 37px;
  font-family: 'Varela Round';
  font-size: 14px;
  font-weight: bold;
  top: -5px;
}
.switch div:before{
  content: 'NO';
  margin-left: 10px;
  color: white;
  left: 100%;
}
.switch div:after{
  content: 'YES';
  right: 100%;
  color: white;
}

.switch-checked{
  background-color : #1BBD36;
  box-shadow: none;
}

#container-floating:hover .plus {
  animation: plus-in 0.15s linear;
  animation-fill-mode: forwards;
}

.edit {
  position: absolute;
  background-image: url(./assets/Icons/phone.gif);
  background-position: center center;
  background-size: cover;
  display: block !important;
  color: white;
  border-radius: 50%;
  height: 55px;
  width: 55px;
  bottom: 1px;
  left: 0px;
  right: 0;
  padding: 0;
  opacity: 0;
  margin: auto;
  line-height: 65px;
  transform: rotateZ(-70deg);
  transition: all 0.3s;
  animation: edit-out 0.3s;
}

#container-floating:hover .edit {
  animation: edit-in 0.2s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

@keyframes edit-in {
  from {
    opacity: 0;
    transform: rotateZ(-70deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

@keyframes edit-out {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }

  to {
    opacity: 0;
    transform: rotateZ(-70deg);
  }
}

@keyframes plus-in {
  from {
    opacity: 1;
    transform: rotateZ(0deg);
  }

  to {
    opacity: 0;
    transform: rotateZ(180deg);
  }
}

@keyframes plus-out {
  from {
    opacity: 0;
    transform: rotateZ(180deg);
  }

  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

@keyframes bounce-nds {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce-out-nds {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}
.checkbox-text{
  padding: 7px;
  position: absolute;
}

.letter {
  font-size: 23px;
  font-family: 'Roboto';
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  line-height: 40px;
}

.reminder {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  line-height: 40px;
}

.profile {
  border-radius: 50%;
  width: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 20px;
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.counts {
  padding-top: 80px;
}

.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  position: relative;
  text-align: center;
  box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #ffffff;
}

.counts .count-box i {
  position: absolute;
  width: 54px;
  height: 54px;
  top: -27px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: #fff;
  color: #e03a3c;
  border-radius: 50px;
  border: 2px solid #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 700;
  color: #111111;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

.bg-info {
  background-color: #2eca6a;
}

.bg-dark {
  background-color: black;
}

.bg-danger {
  background-color: red;
}

.bg-warning {
  background-color: orange;
}

.small-box {
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.small-box {
  border-radius: .25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
  display: block;
  margin-bottom: 20px;
  position: relative;
  border: 0.5px solid black;
}

.small-box>.inner {
  padding: 10px
}

.small-box>.small-box-footer {
  background-color: rgba(78, 75, 75, 0.1);
  color: black;
  display: block;
  padding: 3px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 10
}

.small-box>.small-box-footer:hover {
  background-color: rgba(0, 0, 0, .15);
  color: black
}

.container-c {
  padding: 5px;
}

.small-box h3 {
  font-size: 2.2rem;
  font-weight: 700;
  margin: 0 0 10px;
  padding: 0;
  white-space: nowrap
}

@media (min-width:992px) {

  .col-lg-2 .small-box h3,
  .col-md-2 .small-box h3,
  .col-xl-2 .small-box h3 {
    font-size: 1.6rem
  }

  .col-lg-3 .small-box h3,
  .col-md-3 .small-box h3,
  .col-xl-3 .small-box h3 {
    font-size: 1.6rem
  }
}

@media (min-width:1200px) {

  .col-lg-2 .small-box h3,
  .col-md-2 .small-box h3,
  .col-xl-2 .small-box h3 {
    font-size: 2.2rem
  }

  .col-lg-3 .small-box h3,
  .col-md-3 .small-box h3,
  .col-xl-3 .small-box h3 {
    font-size: 2.2rem
  }
}

.small-box p {
  font-size: 1rem
}

.small-box p>small {
  color: #f8f9fa;
  display: block;
  font-size: .9rem;
  margin-top: 5px
}

.small-box h3,
.small-box p {
  z-index: 5
}

.small-box .icon {
  color: rgba(0, 0, 0, .15);
  z-index: 0
}

.small-box .icon>i {
  font-size: 90px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: -webkit-transform .3s linear;
  transition: transform .3s linear;
  transition: transform .3s linear, -webkit-transform .3s linear
}

.small-box .icon>i.fa,
.small-box .icon>i.bi,
.small-box .icon>i.ion {
  font-size: 70px;
  top: 20px
}

.small-box .icon svg {
  font-size: 70px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: -webkit-transform .3s linear;
  transition: transform .3s linear;
  transition: transform .3s linear, -webkit-transform .3s linear
}

.small-box:hover {
  text-decoration: none
}

.small-box:hover .icon>i,
.small-box:hover .icon>i.fa,
.small-box:hover .icon>i.bi,
.small-box:hover .icon>i.ion {
  -webkit-transform: scale(1.1);
  transform: scale(1.1)
}

.small-box:hover .icon>svg {
  -webkit-transform: scale(1.1);
  transform: scale(1.1)
}

@media (max-width:767.98px) {
  .small-box {
    text-align: center
  }

  .small-box .icon {
    display: none
  }

  .small-box p {
    font-size: 12px
  }
}

@media (min-width: 992px) {

  .img-b {
    max-height: 600px;
  }
}

@media (min-width: 740px) {

  .img-b {
    max-height: 500px;
  }
}

.fa-whatsapp {
  color: #34B7F1;
}

.fa-facebook {
  color: #4267B2;
}

.fa-instagram {
  color: #8a3ab9;
}
.email-link{
  cursor: pointer;
}

.fa-twitter {
  color: #00acee;
}

.text-small {
  font-size: small;
  text-transform: lowercase;
  padding-left: 3px;
}

.modal-dialog .modal-fullscreen {
  background-color: transparent;
  background: transparent;

}

.fade .modal-backdrop .show {
  background-color: transparent;
  background: transparent;

}

.img-fluid-c {
  padding: 2px;

}


/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .img-fluid-c {
    min-height: 400px;
    max-height: 400px;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .img-fluid-c {
    min-height: 450px;
    max-height: 450px;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .img-fluid-c {
    min-height: 500px;
    max-height: 500px;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .img-fluid-c {
    min-height: 600px;
    max-height: 600px;
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .img-fluid-c {
    min-height: 700px;
    max-height: 800px;
  }
}


.accordion__button:hover{
  color: #2eca6a;
  background-color: transparent !important;
}
.accordion__panel {
  cursor: pointer;
  
}
.accordion__panel .fa .fa-circle{
  color: #2eca6a;
}